@import url("https://fonts.googleapis.com/css2?family=Yatra+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;

  background: #8080802e;
}

h1 {
  font-family: "Yatra One", cursive;
  font-size: 4.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
